import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { WorkHistoryService } from "src/app/features/worker/services/work-history.service";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { DashboardPagesStateDefaults, SetError } from "src/app/shared/states/dashboard.state";
import { SetFilter } from "../../../../shared/states/prefill-data/prefill-data.actions";
import { getFilterData } from "../../../../shared/utils/utils";
import { LaborFilters } from "../../interfaces/labor-filters.interface";
import { RosterService } from "../../services/roster.service";
import {
	GetWorkHistory,
	GetWorkerByUnionId,
	ImportCertifications,
	ImportDebits,
	LoadWorkerStatus,
	LoadWorkers,
	RemoveWorker,
	ResetFilteredItems,
	UpdateLaborFilters
} from "./roster.actions";
import { RosterStateModel } from "./roster.model";

@State<RosterStateModel>({
	name: "roster",
	defaults: {
		...DashboardPagesStateDefaults,
		filteredItems: [],
		status: [],
		selectedWorkerHistory: [],
		totalWorkers: null,
		totalFilteredWorkers: null,
		filters: null
	}
})
@Injectable()
export class RosterState {
	constructor(
		private rosterService: RosterService,
		private workHistoryService: WorkHistoryService
	) { }

	@Selector() static filteredItems(state: RosterStateModel) { return state.filteredItems; }
	@Selector() static loading(state: RosterStateModel) { return state.loading; }
	@Selector() static saving(state: RosterStateModel) { return state.saving; }
	@Selector() static totalFilteredWorkers(state: RosterStateModel) { return state.totalFilteredWorkers; }
	@Selector() static selectedWorkerHistory(state: RosterStateModel) { return state.selectedWorkerHistory; }
	@Selector() static totalWorkerHistory(state: RosterStateModel) { return state.totalWorkerHistory; }
	@Selector() static totalHoursWorkerHistory(state: RosterStateModel) { return state.totalHoursWorkerHistory; }
	@Selector() static filters(state: RosterStateModel) { return state.filters; }
	@Selector() static totalWorkers(state: RosterStateModel) { return state.totalWorkers; }
	@Selector() static workers(state: RosterStateModel) { return state.items; }
	@Selector() static workerStatus(state: RosterStateModel) { return state.status; }

	@Action(LoadWorkers)
	loadWorkers(ctx: StateContext<RosterStateModel>, { skipCount, maxResult }) {
		ctx.patchState({ items: [], loading: true });
		const filters: LaborFilters = ctx.getState().filters || {};

		return this.rosterService.getWorkers(skipCount, maxResult, filters).subscribe(
			(response) => {
				ctx.patchState({
					items: response.items,
					totalWorkers: response.totalCount,
					loading: false
				});
			},
			(error) => {
				ctx.patchState({ items: [], loading: false });
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

	@Action(GetWorkerByUnionId)
	getWorkerByUnionId(ctx: StateContext<RosterStateModel>, { query, unionId, skipCount, maxResult }) {
		ctx.patchState({
			filteredItems: [],
			totalFilteredWorkers: 0,
			loading: true
		});

		return this.rosterService.getWorker(query, unionId, skipCount, maxResult, null, null, null, null, false, false).subscribe(
			res => {
				ctx.patchState({
					filteredItems: res.items,
					loading: false,
					totalFilteredWorkers: res.totalCount
				});
			},
			error => {
				ctx.patchState({ loading: false });
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

	@Action(RemoveWorker)
	removeWorker(ctx: StateContext<RosterStateModel>, { workerId, callback }) {
		const state = ctx.getState();

		return this.rosterService.remove(workerId).subscribe(
			res => {
				callback();
				if (state.items) {
					const updatedWorkers = state.items.filter(item => {
						return item.id !== workerId;
					});
					ctx.patchState({ items: updatedWorkers });
				}
			},
			error => {
				callback(error);
				ctx.dispatch(new SetError({ removing: error }));
			}
		);
	}

	@Action(ResetFilteredItems)
	resetFilteredItems(ctx: StateContext<RosterStateModel>, { }) {
		ctx.patchState({ filteredItems: [] });
	}

	@Action(GetWorkHistory)
	getWorkHistory(ctx: StateContext<RosterStateModel>, { skipCount, maxResult, workerId, filters }) {
		ctx.patchState({
			loading: true,
			selectedWorkerHistory: [],
			totalWorkerHistory: 0,
			totalHoursWorkerHistory: 0
		});

		return this.workHistoryService
			.getWorkHistory(skipCount, maxResult, workerId, filters)
			.subscribe(
				res => {
					ctx.patchState({
						selectedWorkerHistory: res.items,
						totalWorkerHistory: res.totalCount,
						totalHoursWorkerHistory: res.totalHours,
						loading: false
					});

					if (!filters) {
						const terminalsArray = getFilterData(res, "AvailableTerminals");
						ctx.dispatch(new SetFilter("filteredTerminals", terminalsArray));

						const employerssArray = getFilterData(res, "AvailableEmployers");
						ctx.dispatch(new SetFilter("filteredEmployers", employerssArray));
					}
				},
				error => {
					ctx.patchState({
						loading: false,
						selectedWorkerHistory: [],
						totalWorkerHistory: 0,
						totalHoursWorkerHistory: 0
					});
					ctx.dispatch(new SetError({ loading: true }));
				}
			);
	}

	@Action(UpdateLaborFilters)
	updateFilters(ctx: StateContext<RosterStateModel>, { filters }) {
		ctx.patchState({ filters });
	}

	@Action(LoadWorkerStatus)
	loadWorkerStatus(ctx: StateContext<RosterStateModel>) {
		const state: RosterStateModel = ctx.getState();
		if (!state.status.length) {
			return this.rosterService.getWorkerStatus().pipe(
				tap(
					(response: SelectOption[]) => {
						ctx.patchState({
							status: response
						});
					}
				)
			);
		}
	}

	@Action(ImportCertifications)
  importCertifications(ctx: StateContext<RosterStateModel>, action: ImportCertifications) {
    return this.rosterService.importCertifications(action.payload).toPromise().then(
      (response) => {
        action.callbackSuccess(response);
      },
      () => {
        action.callbackError();
      }
    );
  }

	@Action(ImportDebits)
  importDebits(ctx: StateContext<RosterStateModel>, action: ImportDebits) {
    return this.rosterService.importDebits(action.payload).toPromise().then(
      (response) => {
        action.callbackSuccess(response);
      },
      () => {
        action.callbackError();
      }
    );
  }

}