import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { DashboardPageStateModel } from "src/app/shared/states/dashboard.state";
import { LaborFilters } from "../../interfaces/labor-filters.interface";

export class RosterStateModel extends DashboardPageStateModel {
	items: Array<any>;
	filteredItems: Array<any>;

  status: SelectOption[];

	totalWorkers: number;
	totalFilteredWorkers: number;

	selectedWorkerHistory?: any;
	totalWorkerHistory?: number;
	totalHoursWorkerHistory?: number;

	filters: LaborFilters;
}