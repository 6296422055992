import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { API_URL } from "src/environments/settings";
import { Filters } from "../../../shared/interfaces/filters.interface";
import { getParamsFromFilters } from "../../../shared/utils/utils";

@Injectable({
  providedIn: "root"
})
export class WorkHistoryService {
  private apiUrl = this.env.getValueTransferState(API_URL);

  constructor(
    private http: HttpClient, 
    private env: EnvironmentService
  ) {}

  getWorkHistory(skipCount, maxResult, workerId, filters?: Filters) {
    const filterParams = getParamsFromFilters(filters);

    return this.http.get<any>(
      `${this.apiUrl}/app/worker/${workerId}/workerHistory?MaxResultCount=${maxResult}&SkipCount=${skipCount}${filterParams}`
    );
  }
}
